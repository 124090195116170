import { FikaItem } from '../types/FikaItem'
import { FikaState, FikaItemState } from './orderContext'
import { LOCAL_STORAGE_KEY } from './orderContext'
import { omit } from 'lodash'

export enum Types {
  Delete = 'DELETE',
  Add = 'ADD',
  DeleteNewItem = 'DELETE_NEW',
  Purge = 'PURGE',
}

export type Action =
  | { type: Types.Add; create: FikaItem }
  | { type: Types.Delete; id: string }
  | { type: Types.DeleteNewItem; id: string }
  | { type: Types.Purge }

export const itemReducer = (
  state: Record<string, FikaItemState>,
  newItemsState: FikaItem[],
  action: Action,
): FikaState => {
  switch (action.type) {
    case Types.Add: {
      state[action.create.id] = {
        item: action.create,
        amount: state[action.create.id]
          ? state[action.create.id].amount + 1
          : 1,
      }
      typeof window !== 'undefined' &&
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state))
      return {
        items: state,
        newItems: [
          ...newItemsState,
          {
            ...action.create,
            id: (newItemsState.length + Math.random()).toString(),
          },
        ],
      }
    }
    case Types.Delete: {
      if (action.id in state) {
        state[action.id].amount = state[action.id].amount - 1
        if (state[action.id].amount == 0) {
          state = omit(state, action.id)
        }
      }
      newItemsState.splice(
        newItemsState.findIndex((product) => product.id == action.id),
        1,
      )
      typeof window !== 'undefined' &&
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state))
      return {
        items: state,
        newItems: newItemsState,
      }
    }
    case Types.Purge:
      typeof window !== 'undefined' &&
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({}))
      return {
        items: {},
        newItems: [],
      }
    case Types.DeleteNewItem:
      newItemsState.splice(
        newItemsState.findIndex((product) => product.id == action.id),
        1,
      )
      return {
        items: state,
        newItems: newItemsState,
      }
    default:
      return {
        items: state,
        newItems: newItemsState,
      }
  }
}
