import React from 'react'
import { FikaItem } from '../types/FikaItem'
import { itemReducer, Action } from './reducers'

export const LOCAL_STORAGE_KEY = '_ellascafe'

export type FikaItemState = {
  item: FikaItem
  amount: number
}

export type FikaState = {
  items: Record<string, FikaItemState>
  newItems: FikaItem[]
}

const initialState = {
  items: {},
  newItems: [],
}

const OrderContext = React.createContext<{
  state: FikaState
  dispatch: React.Dispatch<Action>
}>({
  state: initialState,
  dispatch: () => null,
})

const mainReducer = ({ items, newItems }: FikaState, action: Action) =>
  itemReducer(items, newItems, action)

const AppProvider: React.FC = ({ children }) => {
  let localState: FikaState | undefined

  if (typeof window !== 'undefined') {
    const localStorageString = localStorage.getItem(LOCAL_STORAGE_KEY)
    const parsedLocalState =
      localStorageString && JSON.parse(localStorageString)
    localState = parsedLocalState && {
      items: parsedLocalState,
      newItems: [],
    }
  }
  const [state, dispatch] = React.useReducer(
    mainReducer,
    localState || initialState,
  )

  return (
    <OrderContext.Provider value={{ state, dispatch }}>
      {children}
    </OrderContext.Provider>
  )
}

export { OrderContext, AppProvider }
